export type HighlightedPreLanguage =
  | "bash"
  // https://github.com/FormidableLabs/prism-react-renderer/blob/e35950e4f9520f33672e94b798eadfd426ef692d/packages/generate-prism-languages/index.ts#L9
  | "markup"
  | "jsx"
  | "tsx"
  | "swift"
  | "kotlin"
  | "objectivec"
  | "js-extras"
  | "reason"
  | "rust"
  | "graphql"
  | "yaml"
  | "go"
  | "cpp"
  | "markdown"
  | "python";

export interface RenderedTrace {
  slug: string;
  title: string;
  order: number;
  summaryHtml: string;
  summaryItems: RenderedTraceSummaryItem[];
  sections?: RenderedTraceSection[] | undefined;
}
export interface RenderedTraceSummaryItem {
  __html: string;
  text: string;
  partId: number | undefined;
}
export type RenderedTraceSection =
  | {
      type: "text";
      parts: { id: number; value: string; title?: boolean }[];
    }
  | {
      type: "pre";
      parts: {
        id: number;
        value: string;
        language?: HighlightedPreLanguage;
        collapsible?: boolean;
      }[];
    };

export interface HashTrace {
  slug?: string | undefined;
  part?: number | undefined;
}

export const TRACE_TARGET_PART_SEPARATOR = "---";

export function getTraceId(value: HashTrace) {
  const { slug, part } = value;

  let target = "";
  if (slug) {
    target = `${slug}`;
    if (part !== undefined) {
      target += `${TRACE_TARGET_PART_SEPARATOR}${part === -1 ? "X" : part}`;
    }
  }
  return target;
}

import clsx from "clsx";
import {
  type HTMLAttributes,
  type MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Check, Menu, Share, Tool } from "react-feather";
import { SITE_ORIGIN } from "../../consts.ts";
import useAnimateChanging from "../hooks/useAnimateChanging.ts";
import useFetch from "../hooks/useFetch.ts";
import useScreenshotMode from "../hooks/useScreenshotMode.tsx";
import TraceDetailsView from "./TraceDetailsView.tsx";
import TraceProgress from "./TraceProgress.tsx";
import TraceSummaryView from "./TraceSummaryView.tsx";
import { type HashTrace, type RenderedTrace } from "./types.ts";

export type TraceViewerLayersProps = HTMLAttributes<HTMLDivElement> & {
  trace: Omit<RenderedTrace, "sections">;
  disabled?: boolean;

  // We should really be calling `useHashTrace()` inside this component instead,
  // but that causes all sorts of crazy re-rendering.
  hashTrace: HashTrace;
  setHashTrace: (newValue: HashTrace, replace?: boolean) => void;
};
export default function TraceViewerLayers(props: TraceViewerLayersProps) {
  const {
    trace: partialTrace,
    disabled,
    hashTrace,
    setHashTrace,
    className,
    ...rest
  } = props;

  const isScreenshotMode = useScreenshotMode();

  const showFullTrace =
    hashTrace.slug === props.trace.slug && hashTrace.part !== undefined;
  const animatingFull = useAnimateChanging(700, showFullTrace);

  const [onceShowedFullTrace, setOnceShowedFullTrace] = useState(showFullTrace);
  const [onceEnabled, setOnceEnabled] = useState(!disabled);
  useEffect(() => {
    if (showFullTrace) setOnceShowedFullTrace(true);
  }, [showFullTrace]);
  useEffect(() => {
    if (disabled) setOnceShowedFullTrace(false);
    else setOnceEnabled(true);
  }, [disabled]);

  const fullTraceUrl = `/api/trace/${partialTrace.slug}.json`;
  const fullTraceResponse = useFetch<RenderedTrace["sections"]>(
    onceEnabled ? fullTraceUrl : undefined,
    JSON.parse,
  );
  const fullTrace = useMemo<RenderedTrace>(() => {
    return { ...partialTrace, sections: fullTraceResponse.value };
  }, [partialTrace, fullTraceResponse.value]);

  const [$traceContainer, set$traceContainer] = useState<HTMLDivElement | null>(
    null,
  );

  const showTraceSummary = useCallback(
    (e: ReactMouseEvent) => {
      e.preventDefault();
      setHashTrace({ slug: partialTrace.slug }, /* replace */ true);
    },
    [setHashTrace, partialTrace.slug],
  );

  return (
    <div
      onClick={
        disabled ? () => setHashTrace({ slug: partialTrace.slug }) : undefined
      }
      className={clsx(className, disabled && "cursor-pointer")}
      {...rest}
    >
      {/* Main trace viewer */}
      <div
        className={clsx(
          "relative aspect-w-9 aspect-h-16 md:aspect-w-3 md:aspect-h-4 lg:aspect-w-16 lg:aspect-h-9 outline outline-xbow-electric-green rounded-2xl bg-dark-gray-900 overflow-hidden",
          disabled && "pointer-events-none",
        )}
      >
        {isScreenshotMode && (
          <div>
            <div className="absolute right-10 top-[60%] -translate-y-1/2 opacity-10 z-10 pointer-events-none w-[450px]">
              <img src="/xbow-logomark-white.svg" alt="" />
            </div>
          </div>
        )}

        <div>
          {!(
            onceShowedFullTrace || animatingFull
          ) ? null : fullTrace.sections === undefined ? (
            fullTraceResponse.error ? (
              <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl">
                {fullTraceResponse.error}
              </p>
            ) : (
              // Full trace loader
              <svg
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                width="256"
                height="4"
              >
                <rect x="0" y="0" width="100%" height="4" fill="#444444" />
                <rect
                  x="0"
                  y="0"
                  width={`${fullTraceResponse.progress * 100}%`}
                  height="4"
                  className="fill-xbow-electric-green"
                />
              </svg>
            )
          ) : (
            // Full trace
            <TraceDetailsView
              className={clsx(
                "absolute top-0 left-0 bottom-0 right-0 transition-[transform,opacity] duration-700",
                !showFullTrace && "scale-95 opacity-0",
              )}
              trace={fullTrace}
              selectedPart={hashTrace.part}
              set$traceContainer={set$traceContainer}
            />
          )}

          {/* Pull-tab to return to summary */}
          {!isScreenshotMode && (
            <div
              className={clsx(
                "absolute top-0 left-0 bottom-0 right-0 transition-transform duration-700",
                showFullTrace && "-translate-x-full",
              )}
            >
              <a
                className="absolute top-1/2 left-full transition-transform -translate-x-10 hover:-translate-x-8 -translate-y-1/2 size-16 md:size-20 rounded-r-full bg-xbow-electric-green hover:bg-opacity-90 flex items-center justify-end cursor-pointer text-black [&_svg]:size-4 md:[&_svg]:size-6 pr-1.5 md:pr-2.5"
                aria-label="Summary"
                onClick={showTraceSummary}
                href={`#${props.trace.slug}`}
              >
                <Menu />
              </a>
            </div>
          )}

          {/* Summary trace */}
          <TraceSummaryView
            className={clsx(
              "absolute top-0 left-0 bottom-0 right-0 transition-transform duration-700",
              showFullTrace && "-translate-x-full",
            )}
            trace={partialTrace}
            setHashTrace={setHashTrace}
          />

          {/* JavaScript warning curtain */}
          <noscript>
            <div className="absolute top-0 left-0 bottom-0 right-0 z-20 backdrop-blur flex flex-col items-center justify-center">
              {!disabled && (
                <>
                  <Tool className="size-16 md:size-20" />
                  <h2 className="font-bold text-xl md:text-3xl mt-8">
                    JavaScript Disabled
                  </h2>
                  <p className="font-bold text-lg md:text-xl mt-4 opacity-70">
                    Please enable JavaScript to explore examples
                  </p>
                </>
              )}
            </div>
          </noscript>

          <ShareButton slug={partialTrace.slug} title={partialTrace.title} />
        </div>
      </div>

      {/* Timeline */}
      <TraceProgress
        trace={fullTrace}
        visible={!disabled && showFullTrace && fullTrace.sections !== undefined}
        $traceContainer={$traceContainer}
        hashTrace={hashTrace}
        setHashTrace={setHashTrace}
      />
    </div>
  );
}

function ShareButton(props: { title: string; slug: string }) {
  const { title, slug } = props;
  const isScreenshotMode = useScreenshotMode();

  const [copied, setCopied] = useState(false);
  const animatingCopied = useAnimateChanging(300, copied);

  const shareText = `Check out XBOW autonomously "${title}"`;
  const shareUrl = `${SITE_ORIGIN}/#${slug}`;

  const twitterUrl = useMemo(() => {
    const params = new URLSearchParams();
    params.set("text", shareText);
    params.set("url", shareUrl);
    return `https://twitter.com/intent/tweet?${params}`;
  }, [shareText, shareUrl]);

  const timeoutRef = useRef<number>();
  const onShare = useCallback(
    (e: ReactMouseEvent) => {
      if (navigator.share !== undefined) {
        e.preventDefault();
        void navigator
          .share({ text: shareText, url: shareUrl })
          .catch(() => {});
      } else if (navigator.clipboard !== undefined) {
        e.preventDefault();
        void navigator.clipboard
          .writeText(shareUrl)
          .then(() => {
            setCopied(true);
            clearTimeout(timeoutRef.current);
            // @ts-expect-error `setTimeout()` returns `number` in browser
            timeoutRef.current = setTimeout(() => setCopied(false), 3000);
          })
          .catch(() => {});
      }
    },
    [shareText, shareUrl],
  );

  if (isScreenshotMode) {
    return;
  }

  return (
    <>
      <a
        className="absolute top-0 right-0 z-20 size-12 md:size-16 transition-transform translate-x-1 -translate-y-1 hover:translate-x-0 hover:translate-y-0 rounded-bl-full bg-xbow-electric-green hover:bg-opacity-90 cursor-pointer text-black [&_svg]:size-4 md:[&_svg]:size-6"
        aria-label="Share"
        href={twitterUrl}
        target="_blank"
        onClick={onShare}
        rel="noreferrer"
      >
        <Share
          className={clsx(
            "absolute top-3.5 right-3.5 md:top-4 md:right-4 transition-opacity",
            copied && "opacity-0",
          )}
        />
        <Check
          className={clsx(
            "absolute top-3.5 right-3.5 md:top-4 md:right-4 transition-opacity",
            !copied && "opacity-0",
          )}
        />
      </a>
      <p
        className={clsx(
          "absolute top-3.5 md:top-4 right-[3.5rem] md:right-[4.5rem] z-20 bg-dark-gray-900 px-2 py-1 rounded transition-[transform,opacity] duration-300",
          !(copied || animatingCopied) && "invisible",
          !copied && "translate-x-4 opacity-0",
        )}
      >
        Copied to clipboard!
      </p>
    </>
  );
}

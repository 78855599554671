import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const bgClasses = Object.freeze([
  "",
  "bg-white",
  "bg-xbow-electric-green",
  "bg-xbow-forest-green",
]);

const Context = createContext({
  isOn: false,
  bgClass: bgClasses[0],
});
Context.displayName = "XBOW.ScreenshotMode";

export default function useScreenshotMode() {
  const ctx = useScreenshotContext();
  return ctx.isOn;
}

export function useScreenshotContext() {
  return useContext(Context);
}

export function ScreenshotModeProvider(
  props: PropsWithChildren & { "client:load": true },
) {
  const { children } = props;
  const [isOn, setIsOn] = useState(false);
  const [bgClass, setBgClass] = useState(bgClasses[0]);

  const toggleScreenshotMode = useCallback(() => {
    document.body.style.opacity = "0.5";
    setTimeout(() => {
      document.body.style.opacity = "1";
    }, 500);
    setIsOn((isOn) => {
      console.log(`${isOn ? "Disabled" : "Enabled"} screenshot mode`);
      return !isOn;
    });
  }, []);

  const goToNextBg = useCallback(() => {
    setBgClass((bgColor) => {
      const idx = bgClasses.indexOf(bgColor || "") || 0;
      return bgClasses[(idx + 1) % bgClasses.length];
    });
  }, []);

  const onKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.ctrlKey && ev.code === "Backquote") {
        ev.preventDefault();
        ev.stopPropagation();
        if (ev.shiftKey) {
          goToNextBg();
        } else {
          toggleScreenshotMode();
        }
      }
    },
    [toggleScreenshotMode, goToNextBg],
  );

  useEffect(() => {
    if (!window) {
      return;
    }
    window.xbow = {
      ...(window.xbow || {}),
      toggleScreenshotMode: toggleScreenshotMode,
      goToNextBg: goToNextBg,
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown, toggleScreenshotMode, goToNextBg]);

  const value = useMemo(
    () => ({
      isOn,
      bgClass,
    }),
    [isOn, bgClass],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

import clsx from "clsx";
import { useMemo } from "react";
import HighlightedPre from "../core/HighlightedPre.tsx";
import useScreenshotMode from "../hooks/useScreenshotMode.tsx";
import {
  AttachmentAIGenerated,
  AttachmentBenchmarkDescription,
  AttachmentTerminalOutput,
} from "./Attachment.tsx";
import CollapsiblePre from "./CollapsiblePre.tsx";
import {
  getTraceId,
  type HighlightedPreLanguage,
  type RenderedTraceSection,
} from "./types.ts";

const COLLAPSE_THRESHOLD = 20;

export interface TraceDetailsSectionPartProps<
  Type extends RenderedTraceSection["type"],
> {
  slug: string;
  part: (RenderedTraceSection & { type: Type })["parts"][number];
  isFirst: boolean;
  isSolverGeneratedContent: boolean;
}

export function TraceDetailsSectionTextPart({
  slug,
  part,
  isFirst,
  isSolverGeneratedContent,
}: TraceDetailsSectionPartProps<"text">) {
  const isScreenshotMode = useScreenshotMode();

  const id = getTraceId({ slug, part: part.id });

  return part.title ? null : (
    <pre
      id={id}
      className={clsx(
        "relative whitespace-pre-wrap transition-opacity",
        !isScreenshotMode && "opacity-70 hover:opacity-100",
        !isSolverGeneratedContent && "font-sans",
      )}
    >
      {isFirst && isSolverGeneratedContent && (
        <AttachmentAIGenerated href={`#${id}`} />
      )}
      {part.value}
    </pre>
  );
}

export function TraceDetailsSectionPrePart({
  slug,
  part,
  part0Language,
  isFirst,
  isSolverGeneratedContent,
}: TraceDetailsSectionPartProps<"pre"> & {
  part0Language: HighlightedPreLanguage | undefined;
}) {
  const isScreenshotMode = useScreenshotMode();

  const linesIfCollapsible = useMemo(() => {
    return part.collapsible ? part.value.split("\n") : undefined;
  }, [part.collapsible, part.value]);

  if (part.value === "") return null;

  const id = getTraceId({ slug, part: part.id });

  if (part.language !== undefined) {
    return (
      <HighlightedPre
        id={id}
        className="whitespace-pre-wrap break-words relative [&_figure]:hover:opacity-100"
        language={part.language}
        attachment={
          isFirst &&
          isSolverGeneratedContent && (
            <AttachmentAIGenerated
              href={`#${id}`}
              className={clsx(
                "transition-opacity",
                !isScreenshotMode && "opacity-70",
              )}
            />
          )
        }
      >
        {part.value}
      </HighlightedPre>
    );
  }

  if (
    linesIfCollapsible !== undefined &&
    linesIfCollapsible.length > COLLAPSE_THRESHOLD
  ) {
    return (
      <div className="relative">
        <CollapsiblePre
          id={id}
          className={clsx(
            "transition-opacity",
            !isScreenshotMode && "opacity-70 hover:opacity-100",
          )}
          lines={linesIfCollapsible}
        >
          {part0Language && <AttachmentTerminalOutput href={`#${id}`} />}
        </CollapsiblePre>
      </div>
    );
  }

  return (
    <pre
      id={id}
      className={clsx(
        "relative whitespace-pre-wrap break-words transition-opacity",
        !isScreenshotMode && "opacity-70 hover:opacity-100",
        !isSolverGeneratedContent && "font-sans",
      )}
    >
      {isFirst ? (
        isSolverGeneratedContent ? (
          <AttachmentAIGenerated href={`#${id}`} />
        ) : (
          <AttachmentBenchmarkDescription href={`#${id}`} />
        )
      ) : part0Language ? (
        <AttachmentTerminalOutput href={`#${id}`} />
      ) : null}
      {part.value}
    </pre>
  );
}

import clsx from "clsx";
import type { MouseEvent as ReactMouseEvent, ReactNode } from "react";
import { Info, Terminal, User } from "react-feather";

export interface AttachmentProps {
  href: string;
  title: string;
  className?: string;
  children?: ReactNode;
}

function onClickAttachment(e: ReactMouseEvent<HTMLAnchorElement>) {
  e.preventDefault();
  history.replaceState(null, "", e.currentTarget.href);
}

export default function Attachment(props: AttachmentProps) {
  return (
    <a
      href={props.href}
      title={props.title}
      className={clsx("absolute top-0 md:top-1", props.className)}
      onClick={onClickAttachment}
    >
      {props.children}
    </a>
  );
}

export function AttachmentInformation(
  props: Pick<AttachmentProps, "href" | "className">,
) {
  return (
    <Attachment
      title="Information"
      className={clsx("-left-6 md:-left-7", props.className)}
      href={props.href}
    >
      <Info />
    </Attachment>
  );
}

export function AttachmentBenchmarkDescription(
  props: Pick<AttachmentProps, "href" | "className">,
) {
  return (
    <Attachment
      title="Benchmark Description"
      className={clsx("-left-6 md:-left-8", props.className)}
      href={props.href}
    >
      <User />
    </Attachment>
  );
}

export function AttachmentAIGenerated(
  props: Pick<AttachmentProps, "href" | "className">,
) {
  return (
    <Attachment
      title="AI Generated"
      className={clsx("-left-6 md:-left-8", props.className)}
      href={props.href}
    >
      <Sparkle />
    </Attachment>
  );
}

export function AttachmentTerminalOutput(
  props: Pick<AttachmentProps, "href" | "className">,
) {
  return (
    <Attachment
      title="Terminal Output"
      className={clsx("-left-[25px] md:-left-8", props.className)}
      href={props.href}
    >
      <Terminal />
    </Attachment>
  );
}

function Sparkle() {
  return (
    <img
      src="/sparkle-white.webp"
      width={20}
      height={20}
      alt=""
      className="select-none"
    />
  );
}

import clsx from "clsx";
import {
  TraceDetailsSectionPrePart,
  TraceDetailsSectionTextPart,
} from "./TraceDetailsSectionParts.tsx";
import type { RenderedTraceSection } from "./types.ts";

export interface TraceDetailsSectionProps {
  slug: string;
  section: RenderedTraceSection;
  isSolverGeneratedContent: boolean;
}
export default function TraceDetailsSection({
  slug,
  section,
  isSolverGeneratedContent,
}: TraceDetailsSectionProps) {
  return section.type === "text" ? (
    <section className="pl-8 md:pl-16 pr-8 py-8 space-y-8">
      {section.parts
        .filter((x) => !x.title)
        .map((part, j) => (
          <TraceDetailsSectionTextPart
            key={j}
            slug={slug}
            part={part}
            isFirst={j === 0}
            isSolverGeneratedContent={isSolverGeneratedContent}
          />
        ))}
    </section>
  ) : (
    <section
      className={clsx(
        "pl-8 md:pl-16 pr-8 py-8 flex flex-col gap-8 bg-black shadow-2xl",
        !isSolverGeneratedContent && "text-xbow-white",
      )}
    >
      {section.parts
        .filter((x) => x.value)
        .map((part, j) => (
          <TraceDetailsSectionPrePart
            key={j}
            slug={slug}
            part={part}
            isFirst={j === 0}
            part0Language={section.parts[0]?.language}
            isSolverGeneratedContent={isSolverGeneratedContent}
          />
        ))}
    </section>
  );
}

import { useCallback, useMemo } from "react";
import {
  getTraceId,
  type HashTrace,
  TRACE_TARGET_PART_SEPARATOR,
} from "../traces/types.ts";
import useHash from "./useHash.ts";

const HASH_TRACE_REGEXP = new RegExp(
  String.raw`#(?<slug>.+?)(?:${TRACE_TARGET_PART_SEPARATOR}(?<part>(X|\d+)))?$`,
);

export function hashTraceFromHash(hash: string): HashTrace {
  if (!hash) {
    return {};
  }
  const match = HASH_TRACE_REGEXP.exec(hash);
  if (!match) {
    return {};
  }
  const slug = match.groups?.slug;
  const part = match.groups?.part;
  if (part) {
    return { slug, part: part === "X" ? -1 : Number.parseInt(part) };
  } else {
    return { slug };
  }
}

export default function useHashTrace(): [
  HashTrace,
  (newValue: HashTrace, replace?: boolean) => void,
] {
  const [hash, setHash] = useHash();

  const hashTrace = useMemo(() => hashTraceFromHash(hash), [hash]);

  const setHashTrace = useCallback(
    (newValue: HashTrace, replace?: boolean) => {
      const id = getTraceId(newValue);
      setHash(`#${id}`, replace);
    },
    [setHash],
  );

  return [hashTrace, setHashTrace];
}

import type { MouseEvent as ReactMouseEvent } from "react";

export function isOnScreen(el: HTMLElement | null): boolean {
  if (!el) {
    return false;
  }
  const r = el.getBoundingClientRect();
  const vh = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight,
  );
  return r.bottom >= 0 && r.top < vh;
}

export function onClickAnchorSmooth(e: ReactMouseEvent<HTMLAnchorElement>) {
  const href = e.currentTarget.getAttribute("href");
  if (href === null) return;
  const $el = document.querySelector(href);
  if ($el === null) return;
  e.preventDefault();
  history.pushState(null, "", e.currentTarget.href);
  window.dispatchEvent(new HashChangeEvent("hashchange"));
  $el.scrollIntoView({ behavior: "smooth" });
}

import clsx from "clsx";
import {
  type MouseEvent as ReactMouseEvent,
  useCallback,
  useState,
} from "react";
import type { HashTrace, RenderedTrace } from "./types.ts";

export interface TraceSummaryViewerProps {
  className?: string;
  trace: RenderedTrace;
  setHashTrace: (newValue: HashTrace, replace?: boolean) => void;
}
export default function TraceSummaryView(props: TraceSummaryViewerProps) {
  const [hovering, setHovering] = useState(false);
  const [offset, setOffset] = useState<{ top: number; height: number }>();

  const onMouseEnter = useCallback((e: ReactMouseEvent<HTMLLIElement>) => {
    const top = e.currentTarget.offsetTop;
    const height = e.currentTarget.offsetHeight;
    setOffset({ top, height });
    setHovering(true);
  }, []);
  const onMouseLeave = useCallback((_e: ReactMouseEvent<HTMLOListElement>) => {
    setHovering(false);
  }, []);

  const indicatorTransform = offset && `translateY(${offset.top}px)`;
  const indicatorHeight = offset && `${offset.height + 16}px`;
  const indicatorPillTransform = !hovering && "translateX(-8px) scale(0.5)";

  return (
    <div
      className={clsx(
        "bg-black text-lg md:text-xl overflow-x-hidden overflow-y-scroll overscroll-contain py-8 space-y-8 shadow-2xl [&_strong]:font-bold [&_strong]:text-xbow-electric-green",
        props.className,
      )}
    >
      {/* Unequal margins for share button */}
      <h2
        className="pl-8 pr-12 text-lg md:text-2xl font-bold opacity-70 cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          props.setHashTrace({
            slug: props.trace.slug,
            part: 0,
          });
        }}
      >
        {props.trace.title}
      </h2>

      <div
        className="px-8 leading-relaxed font-bold cursor-pointer [&_a[href^='http']]:underline"
        dangerouslySetInnerHTML={{ __html: props.trace.summaryHtml }}
        onClick={(e) => {
          e.preventDefault();
          if (
            e.target instanceof HTMLAnchorElement &&
            e.target.href.startsWith("http")
          ) {
            window.open(e.target.href, "_blank", "noopener noreferrer");
            return;
          }
          props.setHashTrace({
            slug: props.trace.slug,
            part: -1,
          });
        }}
      />

      <div className="relative z-10">
        <div
          className="absolute -z-10 -top-2 left-0 right-0 bg-dark-gray-900 transition-[transform,height,opacity] duration-500"
          style={{
            transform: indicatorTransform,
            height: indicatorHeight,
            opacity: hovering ? 1 : 0,
          }}
        />
        <div
          className="absolute z-20 w-2 -top-2 left-0 bg-xbow-electric-green rounded-r-lg transition-[transform,height,opacity] duration-500 origin-left"
          style={{
            transform: clsx(indicatorTransform, indicatorPillTransform),
            height: indicatorHeight,
          }}
        />

        <ol
          onMouseLeave={onMouseLeave}
          className="pl-14 md:pl-20 list-decimal leading-relaxed transition-opacity opacity-50 hover:opacity-100"
        >
          {props.trace.summaryItems.map((summaryItem, i) => (
            <li
              className="[&_a]:block [&_a]:pr-8 [&_a]:py-1.5 [&_a[href^='http']]:underline"
              key={i}
              onMouseEnter={onMouseEnter}
              dangerouslySetInnerHTML={summaryItem}
              onClick={(e) => {
                e.preventDefault();
                props.setHashTrace({
                  slug: props.trace.slug,
                  part: summaryItem.partId,
                });
              }}
            />
          ))}
        </ol>
      </div>
    </div>
  );
}

import clsx from "clsx";
import { useEffect, useRef } from "react";
import { AttachmentInformation } from "./Attachment.tsx";
import TraceDetailsSection from "./TraceDetailsSection.tsx";
import { getTraceId, type RenderedTrace } from "./types.ts";

export interface TraceDetailsViewProps {
  className?: string;
  trace: RenderedTrace;
  selectedPart: number | undefined;
  set$traceContainer: ($el: HTMLDivElement | null) => void;
}
export default function TraceDetailsView(props: TraceDetailsViewProps) {
  const { className, trace, selectedPart, set$traceContainer } = props;

  // Scroll to `selectedPart` whenever it changes
  const ref = useRef<HTMLDivElement>(null);
  const selectedId =
    selectedPart !== undefined
      ? getTraceId({ slug: trace.slug, part: selectedPart })
      : undefined;
  const activeIdRef = useRef<string>();
  useEffect(() => {
    set$traceContainer(ref.current);

    const $el = ref.current;
    if ($el === null) return;

    if (selectedId === activeIdRef.current) return;
    activeIdRef.current = selectedId;

    const $part = $el.querySelector<HTMLElement>(`#${selectedId}`);
    if ($part === null) return;

    $el.scrollTo({ top: $part.offsetTop - 32 });

    return () => set$traceContainer(null);
  }, [set$traceContainer, selectedId]);

  const slug = props.trace.slug;

  const sections = props.trace.sections ?? [];
  const section0 = sections[0];

  if (!section0) {
    return null;
  }

  const informationId = getTraceId({ slug, part: -1 });

  return (
    <div
      ref={ref}
      className={clsx(
        "text-[0.9rem] md:text-lg overflow-x-hidden overflow-y-scroll overscroll-contain bg-dark-gray-900 font-mono",
        className,
      )}
    >
      {/* Wrapper `div` so we can use a `ResizeObserver` to watch for content height changes */}
      <div id={informationId}>
        <section className="px-9 md:px-15 pt-6 font-sans text-xl text-xbow-white bg-black">
          <div className="relative px-1 transition-opacity opacity-70 hover:opacity-100">
            <AttachmentInformation href={`#${informationId}`} />
            XBOW takes the verbatim benchmark description from a human author
            and a list of targets. The trace shows{" "}
            <span className="text-xbow-electric-green">
              XBOW independently solving the benchmark
            </span>{" "}
            with a series of commands. After each command XBOW reviews the
            output and decides what to do next.
          </div>
        </section>

        <TraceDetailsSection
          key={0}
          slug={slug}
          section={section0}
          isSolverGeneratedContent={false}
        />

        <div className="h-0.5 bg-xbow-electric-green" />

        {sections.slice(1).map((section, i) => (
          <TraceDetailsSection
            key={i}
            slug={slug}
            section={section}
            isSolverGeneratedContent={true}
          />
        ))}
      </div>
    </div>
  );
}

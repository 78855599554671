import {
  type HTMLAttributes,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { ArrowRight } from "react-feather";

const COLLAPSE_VISIBLE = 15;

export type CollapsiblePreProps = HTMLAttributes<HTMLDivElement> & {
  lines: string[];
  children?: ReactNode;
};

export default function CollapsiblePre(props: CollapsiblePreProps) {
  const { lines, children, ...rest } = props;
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setExpanded((v) => !v);
  }, []);

  const truncated = lines.length > COLLAPSE_VISIBLE;
  const value = useMemo(() => {
    return expanded
      ? lines.join("\n")
      : lines.slice(0, COLLAPSE_VISIBLE).join("\n");
  }, [lines, expanded]);

  return (
    <div {...rest}>
      <pre className="whitespace-pre-wrap break-words">
        {children}
        {value}
      </pre>
      {truncated && (
        <button
          className="transition-opacity opacity-70 hover:opacity-100 mt-4 flex items-center"
          onClick={toggleExpanded}
        >
          <ArrowRight className={expanded ? "-rotate-90" : "rotate-90"} />
          <span className="ml-2">Show {expanded ? "less" : "more"}</span>
        </button>
      )}
    </div>
  );
}

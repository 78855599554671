import clsx from "clsx";
import { Highlight, Prism, themes } from "prism-react-renderer";
import type { HTMLAttributes, ReactNode } from "react";
import useScreenshotMode from "../hooks/useScreenshotMode.tsx";
import type { HighlightedPreLanguage } from "../traces/types.ts";

(typeof global !== "undefined" ? global : window).Prism = Prism;
// @ts-expect-error Prism languages don't have type definitions
await import("prismjs/components/prism-bash");

export type HighlightedPreProps = HTMLAttributes<HTMLPreElement> & {
  children: string;
  attachment?: ReactNode;
  language: HighlightedPreLanguage;
};

export default function HighlightedPre(props: HighlightedPreProps) {
  const { children, attachment, language, className, ...rest } = props;

  const isScreenshotMode = useScreenshotMode();

  return (
    <Highlight theme={themes.vsDark} code={children} language={language}>
      {({ tokens, getLineProps, getTokenProps }) => (
        <pre
          className={clsx(
            "hover:grayscale-0",
            isScreenshotMode ? "grayscale-0" : "grayscale-60",
            className,
          )}
          {...rest}
        >
          {attachment}
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
}

import { useCallback, useEffect, useState } from "react";

export default function useHash(): [
  string,
  (newHash: string, replace?: boolean) => void,
] {
  const [hash, setHash] = useState("");

  useEffect(() => {
    function onHashChange() {
      setHash(window.location.hash);
    }
    onHashChange();
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, []);

  const updateHash = useCallback((newHash: string, replace = false) => {
    if (replace) history.replaceState(null, "", newHash);
    else history.pushState(null, "", newHash);
    window.dispatchEvent(new HashChangeEvent("hashchange"));
  }, []);

  return [hash, updateHash];
}

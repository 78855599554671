import { useEffect, useRef, useState } from "react";
import usePrevious from "./usePrevious.ts";

/**
 * Returns `true` iff `value` changed within the past `duration` milliseconds.
 * Useful for animating changes to `value`.
 */
export default function useAnimateChanging<T>(duration: number, value: T) {
  const previousValue = usePrevious(value);
  const changed = previousValue !== undefined && previousValue !== value;

  const [animating, setAnimating] = useState(false);

  const timeoutRef = useRef<number>();
  useEffect(() => {
    if (changed) {
      setAnimating(true);
      clearTimeout(timeoutRef.current);
      // @ts-expect-error `setTimeout()` returns a `number`
      timeoutRef.current = setTimeout(() => setAnimating(false), duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed]);

  return changed || animating;
}

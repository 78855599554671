import { useEffect, useRef, useState } from "react";

export default function useFetch<T>(
  url: string | undefined,
  map: (response: string) => T,
): { value: T | undefined; error: string | undefined; progress: number } {
  const [value, setValue] = useState<T>();
  const [error, setError] = useState<string>();
  const [progress, setProgress] = useState(0);

  const lastUrlRef = useRef<string>();
  useEffect(() => {
    if (url === undefined || lastUrlRef.current === url) return;
    lastUrlRef.current = url;

    setValue(undefined);
    setError(undefined);
    setProgress(0);

    // Using XHR instead of `fetch()` for easier progress monitoring
    const xhr = new XMLHttpRequest();
    xhr.addEventListener("load", () => {
      if (url === lastUrlRef.current) {
        setProgress(1);
        setValue(map(xhr.responseText));
      }
    });
    xhr.addEventListener("error", () => {
      if (url === lastUrlRef.current) setError("Failed to fetch data!");
    });
    xhr.addEventListener("progress", (e) => {
      if (url === lastUrlRef.current && e.lengthComputable) {
        setProgress(e.loaded / e.total);
      }
    });
    xhr.open("GET", url, true);
    xhr.send();
  }, [url, map]);

  return { value, error, progress };
}

import clsx from "clsx";
import { useEffect, useState } from "react";
import useHashTrace, { hashTraceFromHash } from "../hooks/useHashTrace.ts";
import {
  ScreenshotModeProvider,
  useScreenshotContext,
} from "../hooks/useScreenshotMode.tsx";
import { isOnScreen, onClickAnchorSmooth } from "../utils/nav-utils.ts";
import TraceViewerLayers from "./TraceViewerLayers.tsx";
import type { RenderedTrace } from "./types.ts";

export interface TraceCarouselProps {
  traces: Omit<RenderedTrace, "sections">[];
}

const CAROUSEL_VISIBLE_BUFFER = 2;

/**
 * If the user visits the website with a trace slug set, we record it here,
 * so that it is not filtered away when we compute displayedTraces. This
 * allows us to deep-link to a trace that does not normally appear in the
 * carousel.
 */
const extraTraceSlugs = new Set<string>();

export default function TraceCarousel({ traces }: TraceCarouselProps) {
  const [hashTrace] = useHashTrace();

  if (hashTrace.slug) {
    extraTraceSlugs.add(hashTrace.slug);
  }

  const displayedTraces = traces.filter(
    (trace) => trace.order >= 0 || extraTraceSlugs.has(trace.slug),
  );

  let index = displayedTraces.findIndex(
    (trace) => trace.slug === hashTrace.slug,
  );
  if (index === -1) index = 0;

  // Don't animate carousel until *after* first client render with correct hash
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setTimeout(() => setMounted(true), 100);

    const traceSlugs = new Set(extraTraceSlugs);
    for (const trace of traces) traceSlugs.add(trace.slug);

    const { slug } = hashTraceFromHash(window.location.hash);
    if (slug && traceSlugs.has(slug)) {
      const $traces = document.getElementById("traces");
      const $carousel = document.getElementById("TraceCarousel--carousel");
      if (!isOnScreen($carousel)) {
        $traces?.scrollIntoView({ behavior: "instant" });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="TraceCarousel" className="overflow-hidden mb-24">
      <section
        id="traces"
        className="flex flex-col justify-center items-center max-w-[76rem] mx-auto mt-24 p-8 text-center"
      >
        <h2 className="font-bold text-2xl md:text-4xl">See XBOW at work</h2>
        <p className="font-bold text-lg md:text-2xl !leading-normal mt-4 opacity-70 max-w-[93%] lg:max-w-[80%] xl:max-w-[65%]">
          XBOW pursues high-level goals by executing commands and reviewing
          their output, without any human intervention.
        </p>
        <p className="font-medium text-lg sm:text-2xl !leading-normal mt-4 opacity-50 max-w-[93%] lg:max-w-[90%] xl:max-w-[70%]">
          These are real examples of XBOW solving benchmarks. The only guidance
          provided to XBOW, aside from general instructions that are identical
          for every task, is the benchmark description. If you&apos;d like to
          see all the data,{" "}
          <a
            href="#faq-labs-and-exercises-details"
            className="text-xbow-electric-green underline"
            onClick={onClickAnchorSmooth}
          >
            click here
          </a>
          .
        </p>
      </section>

      <ScreenshotModeProvider client:load>
        <CarouselSection
          displayedTraces={displayedTraces}
          index={index}
          mounted={mounted}
        />
      </ScreenshotModeProvider>
    </div>
  );
}

function CarouselSection(props: {
  displayedTraces: RenderedTrace[];
  index: number;
  mounted: boolean;
}) {
  const { displayedTraces, index, mounted } = props;
  const length = displayedTraces.length;

  const [hashTrace, setHashTrace] = useHashTrace();
  const { isOn, bgClass } = useScreenshotContext();

  return (
    <section
      id="TraceCarousel--carousel"
      className={clsx(
        "max-w-[calc(100vw-1.25rem)] lg:max-w-[1200px] 2xl:max-w-screen-xl mx-auto px-9 md:px-15 pt-9 pb-24",
        isOn && bgClass,
      )}
    >
      <div className="aspect-w-9 aspect-h-16 md:aspect-w-3 md:aspect-h-4 lg:aspect-w-16 lg:aspect-h-9">
        {displayedTraces.map((trace, i) => {
          let relativePosition = i - index;
          if (relativePosition >= length - CAROUSEL_VISIBLE_BUFFER) {
            relativePosition -= length;
          } else if (relativePosition <= CAROUSEL_VISIBLE_BUFFER - length) {
            relativePosition += length;
          }
          const hidden = Math.abs(relativePosition) > CAROUSEL_VISIBLE_BUFFER;
          const disabled = i !== index;

          return (
            <TraceViewerLayers
              key={trace.slug}
              className={clsx(
                "origin-bottom",
                hidden && "hidden",
                !hidden &&
                  mounted &&
                  "transition-[transform,opacity] duration-1000",
                disabled && "grayscale opacity-30",
              )}
              style={{
                transform: `translateX(${relativePosition * 100}%) scale(${disabled ? 0.9 : 1})`,
              }}
              trace={trace}
              disabled={disabled}
              hashTrace={hashTrace}
              setHashTrace={setHashTrace}
            />
          );
        })}
      </div>
    </section>
  );
}

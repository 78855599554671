import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { getTraceId, type HashTrace, type RenderedTrace } from "./types.ts";

function getTracePosition($trace: HTMLDivElement, y: number) {
  const contentHeight = $trace.scrollHeight - $trace.clientHeight;
  return Math.min(y / contentHeight, 1);
}

interface TraceMarker {
  partId: number;
  title: string;
  position: number;
}
function getTraceMarkers($trace: HTMLDivElement, trace: RenderedTrace) {
  const result: TraceMarker[] = [];
  if (trace.sections === undefined) return result;

  const lastSection = trace.sections[trace.sections.length - 1];
  const lastPart = lastSection?.parts[lastSection.parts.length - 1];
  const lastPartId = lastPart?.id;

  let seenFirstPartId = false;
  let seenLastPartId = false;

  for (const item of trace.summaryItems) {
    if (item.partId === undefined) continue;
    const $part = $trace.querySelector<HTMLElement>(
      `#${getTraceId({ slug: trace.slug, part: item.partId })}`,
    );
    if ($part === null) continue;
    result.push({
      partId: item.partId,
      title: item.text,
      // Should be `- 32`, but `- 33` makes sure circle highlighted on boundary
      position: getTracePosition($trace, $part.offsetTop - 33),
    });

    if (item.partId === 0) seenFirstPartId = true;
    else if (item.partId === lastPartId) seenLastPartId = true;
  }

  if (!seenFirstPartId)
    result.push({ partId: -1, title: "Start", position: 0 });
  if (!seenLastPartId && lastPartId !== undefined) {
    result.push({ partId: lastPartId, title: "End", position: 1 });
  }

  return result;
}

export interface TraceProgressProps {
  trace: RenderedTrace;
  visible: boolean;
  $traceContainer: HTMLDivElement | null;
  hashTrace: HashTrace;
  setHashTrace: (newValue: HashTrace, replace?: boolean) => void;
}
export default function TraceProgress(props: TraceProgressProps) {
  const {
    trace,
    visible,
    $traceContainer: $trace,
    hashTrace,
    setHashTrace,
  } = props;
  const [progress, setProgress] = useState(0);
  const [markerPositions, setMarkerPositions] = useState<TraceMarker[]>([]);

  const [animateResize, setAnimateResize] = useState(false);
  const animateResizeTimeoutRef = useRef<number>();

  useEffect(() => {
    if ($trace === null) return;

    const onScroll = () => {
      setProgress(getTracePosition($trace, $trace.scrollTop));
    };
    $trace.addEventListener("scroll", onScroll, { passive: true });

    const $traceContent = $trace.children[0];
    if ($traceContent === undefined) {
      throw new Error("Expected trace content wrapper");
    }
    let firstResize = true;
    const resizeObserver = new ResizeObserver(() => {
      if (!firstResize) {
        clearTimeout(animateResizeTimeoutRef.current);
        setAnimateResize(true);
        animateResizeTimeoutRef.current = setTimeout(
          () => setAnimateResize(false),
          500,
        ) as unknown as number;
      }
      firstResize = false;

      onScroll();
      setMarkerPositions(getTraceMarkers($trace, trace));
    });
    resizeObserver.observe($traceContent);

    return () => {
      clearTimeout(animateResizeTimeoutRef.current);
      resizeObserver.disconnect();
      $trace.removeEventListener("scroll", onScroll);
    };
  }, [$trace, trace]);

  return (
    <div
      className={clsx(
        "hidden md:block px-2 mt-8 transition-opacity duration-1000",
        !visible && "pointer-events-none opacity-0",
      )}
    >
      <svg width="100%" height="32" className="overflow-visible">
        {/* Progress */}
        <rect x="0" y="14" width="100%" height="4" fill="#444444" />
        <rect
          x="0"
          y="14"
          width={`${progress * 100}%`}
          height="4"
          className={clsx(
            "fill-xbow-electric-green",
            animateResize && "transition-[width] duration-500",
          )}
        />

        {/* Markers */}
        {markerPositions.map((marker) => (
          <MarkerDot
            key={marker.partId}
            marker={marker}
            trace={trace}
            $trace={$trace}
            animateResize={animateResize}
            progress={progress}
            hashTrace={hashTrace}
            setHashTrace={setHashTrace}
          />
        ))}
      </svg>
    </div>
  );
}

function MarkerDot(props: {
  marker: TraceMarker;
  trace: RenderedTrace;
  $trace: HTMLDivElement | null;
  animateResize: boolean;
  hashTrace: HashTrace;
  setHashTrace: (newValue: HashTrace, replace?: boolean) => void;
  progress: number;
}) {
  const {
    marker,
    trace,
    $trace,
    animateResize,
    progress,
    hashTrace,
    setHashTrace,
  } = props;

  const circleClassName = clsx(
    "cursor-pointer duration-500",
    animateResize ? "transition-[cx,fill]" : "transition-fill ",
  );

  const isFilled =
    progress >= marker.position || (marker.position === 1 && progress >= 0.999);
  const fill = isFilled ? "#b7ff4f" : "#444444";

  const onClick = () => {
    if ($trace !== null) {
      $trace.style.scrollBehavior = "smooth";
      setTimeout(() => ($trace.style.scrollBehavior = ""));
    }

    const targetPart = marker.partId;
    if (hashTrace.part === targetPart) {
      // If we've clicked the same part of the hash, updating the hash
      // won't trigger a scroll, so scroll ourselves
      if ($trace === null) return;
      const targetId = `#${getTraceId({ slug: trace.slug, part: targetPart })}`;
      const $part = $trace.querySelector<HTMLElement>(targetId);
      if ($part === null) return;
      $trace.scrollTo({ top: $part.offsetTop - 32 });
    } else {
      setHashTrace(
        { slug: trace.slug, part: marker.partId },
        /*  replace*/ true,
      );
    }
  };

  return (
    <circle
      className={circleClassName}
      cx={`${marker.position * 100}%`}
      cy="16"
      r="16"
      fill={fill}
      onClick={onClick}
    >
      <title>{marker.title}</title>
    </circle>
  );
}
